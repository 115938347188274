module.exports = [{
      plugin: require('/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119899585-1","head":true},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
